// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/assets/images/logo/index.ts"
);
import.meta.hot.lastModified = "1738830140000";
}
// REMIX HMR END

import logoWhite from './logo-wealth-creation-index-white.svg'
import logoBlack from './logo-wealth-creation-index-black.svg'
import logoBlue from './logo-wealth-creation-index-blue.svg'

export {
  logoWhite,
  logoBlack,
  logoBlue
}